import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "CaughtYouIn8K";

const CaughtYouIn8K = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  return (
    <>
      <a
        href="https://www.youtube.com/watch?v=hQydgm1AaR8"
        target="_blank"
        rel="noreferrer"
      >
        <img
          onClick={() => handleLinkClick("Cheesecake")}
          id="caughtyouin8k"
          src="images/8k.jpeg"
          alt=""
        />
      </a>
    </>
  );
};

export default CaughtYouIn8K;
