import React, { useState, useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Jclvsh";
const DEFAULT_CATEGORY = {
  all: true,
  apps: false,
  ai: false,
  fintech: false,
  ecommerce: false,
  defi: false,
  nfts: false,
  daos: false,
  gaming: false,
  ethereum: false,
  polygon: false,
  bsc: false,
  solana: false,
  terra: false,
  flow: false
};

const Jclvsh = () => {
  const [category, setCategory] = useState(DEFAULT_CATEGORY);

  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  const handleButtonClick = (button) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + button);
  };

  function handleCardClick(card) {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + card);
  }

  const handleCategoryClick = (category) => {
    if (category === "all") {
      setCategory({
        all: true,
        apps: false,
        ai: false,
        fintech: false,
        ecommerce: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "apps") {
      setCategory({
        all: false,
        apps: true,
        ai: false,
        fintech: false,
        ecommerce: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "ai") {
      setCategory({
        all: false,
        apps: false,
        ai: true,
        fintech: false,
        ecommerce: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "fintech") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        fintech: true,
        ecommerce: false,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "ecommerce") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        fintech: false,
        ecommerce: true,
        defi: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "defi") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        fintech: false,
        ecommerce: false,
        defi: true,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "nfts") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: true,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "daos") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: true,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "gaming") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: true,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "ethereum") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: true,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "polygon") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: true,
        bsc: false,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "bsc") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: true,
        solana: false,
        terra: false,
        flow: false
      });
    } else if (category === "solana") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: true,
        terra: false,
        flow: false
      });
    } else if (category === "terra") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: true,
        flow: false
      });
    } else if (category === "flow") {
      setCategory({
        all: false,
        apps: false,
        ai: false,
        defi: false,
        fintech: false,
        ecommerce: false,
        nfts: false,
        daos: false,
        gaming: false,
        ethereum: false,
        polygon: false,
        bsc: false,
        solana: false,
        terra: false,
        flow: true
      });
    }
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <h1 className="heading">jclvsh</h1>
          <p className="paragraph">outsource your mvp</p>
          <div className="div-block-2">
            <a
              href="mailto:josh@jclvsh.art"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/gmail.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("GMail")}
              />
            </a>
            <a
              href="https://www.toptal.com/resume/josh-choi"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/toptal.png"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Toptal")}
              />
            </a>
            <a
              href="https://app.usebraintrust.com/talent/50343/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/braintrust.png"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Braintrust")}
              />
            </a>
            <a
              href="https://www.upwork.com/freelancers/~011b4aa7ac78aae607"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/upwork.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Upwork")}
              />
            </a>
            <a
              href="https://www.fiverr.com/joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="logos/fiverr.svg"
                loading="lazy"
                alt=""
                className="image"
                onClick={() => handleLinkClick("Fiverr")}
              />
            </a>
          </div>
        </div>
        <div className="div-block-3">
          <div className="buttons">
            <button
              id={category["all"] ? "all-clicked" : "all-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("all");
                handleButtonClick("all");
              }}
            >
              all
            </button>
            <button
              id={category["apps"] ? "apps-clicked" : "apps-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("apps");
                handleButtonClick("apps");
              }}
            >
              apps
            </button>
            <button
              id={category["ai"] ? "ai-clicked" : "ai-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("ai");
                handleButtonClick("ai");
              }}
            >
              ai
            </button>
            <button
              id={category["fintech"] ? "fintech-clicked" : "fintech-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("fintech");
                handleButtonClick("fintech");
              }}
            >
              fintech
            </button>
            <button
              id={category["ecommerce"] ? "ecommerce-clicked" : "ecommerce-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("ecommerce");
                handleButtonClick("ecommerce");
              }}
            >
              ecommerce
            </button>
            <button
              id={category["defi"] ? "defi-clicked" : "defi-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("defi");
                handleButtonClick("defi");
              }}
            >
              defi
            </button>
            <button
              id={category["nfts"] ? "nfts-clicked" : "nfts-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("nfts");
                handleButtonClick("nfts");
              }}
            >
              nfts
            </button>
            <button
              id={category["daos"] ? "daos-clicked" : "daos-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("daos");
                handleButtonClick("daos");
              }}
            >
              daos
            </button>
            <button
              id={category["gaming"] ? "gaming-clicked" : "gaming-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("gaming");
                handleButtonClick("gaming");
              }}
            >
              gaming
            </button>
            <button
              id={
                category["ethereum"] ? "ethereum-clicked" : "ethereum-unclicked"
              }
              className="btn button"
              onClick={() => {
                handleCategoryClick("ethereum");
                handleButtonClick("ethereum");
              }}
            >
              ethereum
            </button>
            <button
              id={category["polygon"] ? "polygon-clicked" : "polygon-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("polygon");
                handleButtonClick("polygon");
              }}
            >
              polygon
            </button>
            <button
              id={category["bsc"] ? "bsc-clicked" : "bsc-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("bsc");
                handleButtonClick("bsc");
              }}
            >
              binance smart chain
            </button>
            {/*
              <button
                id={category["solana"] ? "solana-clicked" : "solana-unclicked"}
                className="btn button"
                onClick={() => {
                  handleCategoryClick("solana");
                  handleButtonClick("solana");
                }}
              >
                solana
              </button>
            */}
            {/*
              <button
                id={category["terra"] ? "terra-clicked" : "terra-unclicked"}
                className="btn button"
                onClick={() => {
                  handleCategoryClick("terra");
                  handleButtonClick("terra");
                }}
              >
                terra
              </button>
            */}
            <button
              id={category["flow"] ? "flow-clicked" : "flow-unclicked"}
              className="btn button"
              onClick={() => {
                handleCategoryClick("flow");
                handleButtonClick("flow");
              }}
            >
              flow
            </button>
          </div>
          <div className="cards wrap">

            {/* Open Book Club */}
            <a
              href="https://openbookclub.net/auth"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Open Book Club")}
            >
              <img id="open-book-club-logo" src="logos/open-book-club-logo.png" alt="open book club logo" />
              <p className="paragraph" style={{ color: "#FF0000" }}>
                <span style={{ fontWeight: "bold" }}>ideation to mvp in 6 weeks from march 2024 to april 2024</span>
              </p>
              <p className="paragraph" style={{ color: "#E60000" }}>
                a social book club for people who want to read and discuss books together
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#CC0000" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> full-stack mvp
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#B30000" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> phone authentication, onboarding flow, web scraping, profiles, social feed
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#990000" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, tailwind, shadcn, javascript, react, node.js, express, google books api, mongodb, heroku
              </p>
              <img src="images/open-book-club-homepage.png" alt="open book club homepage" />
            </a>

            {/* Vandra */}
            <a
              href="https://www.vandra.ai/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["ai"] ||
                  category["ecommerce"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Vandra")}
            >
              <img
                src="logos/vandra-logo.png"
                alt="vandra logo"
              />
              <p className="paragraph" style={{ color: "#8A2BE2" }}>
                <span style={{ fontWeight: "bold" }}>lead full-stack engineer from march 2023 to may 2024</span>
              </p>
              <p className="paragraph" style={{ color: "#7B68EE" }}>
                <span style={{ fontWeight: "bold" }}>raised $6m in seed funding q1 2024</span>
              </p>
              <p className="paragraph" style={{ color: "#6A5ACD" }}>ai-driven discounting for ecommerce</p>
              <p className="paragraph" style={{ textAlign: "left", color: "#483D8B" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> shopify theme extension, shopify checkout extension, merchant portal, admin portal, machine learning models
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#4B0082" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> extensions, dashboard, analytics, ecommerce, ai
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#3A0066" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, javscript, react, python, flask, shopify api, h20 api, sqlalchemy, postgresql
              </p>
              <img src="images/vandra-homepage.png" alt="vandra homepage" />
            </a>

            {/* 3nstar */}
            <a
              href="https://centserv.herokuapp.com/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("3nStar")}
            >
              <img src="logos/3nstar-logo.png" alt="3nstar logo" />
              <p className="paragraph" style={{ color: "#40E0D0" }}>
                <span style={{ fontWeight: "bold" }}>ideation to mvp in 2023</span>
              </p>
              <p className="paragraph" style={{ color: "#20B2AA" }}>
                a service center inventory management platform for 3nstar
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#008B8B" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> full-stack mvp
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#00688B" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> email authentication; admin and service center views;
                inventories; replenish, adjustment, and service tickets; admin
                crud; csv file upload; export to csv file
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#004F4F" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, tailwind, javascript, react, redux,
                node.js, express, prisma, postgresql, heroku
              </p>
              <img src="images/3nstar-homepage.png" alt="3nstar homepage" />
            </a>

            {/* Fantera */}
            <a
              href="https://fantera-webapp-v1.vercel.app/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Fantera")}
            >
              <img src="logos/fantera-logo.png" alt="fantera logo" />
              <p className="paragraph" style={{ color: "#808080" }}>
                <span style={{ fontWeight: "bold" }}>ideation to mvp in 6 weeks from november 2022 to december 2022</span>
              </p>
              <p className="paragraph" style={{ color: "#707070" }}>football team ownership through nfts and dao governance</p>
              <p className="paragraph" style={{ textAlign: "left", color: "#606060" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> full-stack mvp
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#505050" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> email, social, and wallet authentication; dashboard;
                challenges; votes; media; smart contracts; nfts; daos; ethereum
                blockchain; polygon blockchain; web3
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#404040" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, tailwind, javascript, react, next.js,
                redux, firebase, vercel, ethereum, polygon
              </p>
              <img src="images/fantera-homepage.png" alt="fantera homepage" />
            </a>

            {/* Reitium */}
            <a
              href="https://reitium.fund/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Reitium")}
            >
              <img src="logos/reitium-logo.png" alt="reitium logo" />
              <p className="paragraph" style={{ color: "#4169E1" }}>
                <span style={{ fontWeight: "bold" }}>senior react developer from october 2022 to january 2023</span>
              </p>
              <p className="paragraph" style={{ color: "#1E90FF" }}>
                a technology platform that makes investing simple and
                accessible to all by providing a safe, secure, reliable, trading
                platform for private investments
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#4682B4" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> consumer-facing investment portal
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#0000CD" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> email authentication, dashboard, marketplace,
                portfolio
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#00008B" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, tailwind, javascript, typescript, react,
                next.js, redux
              </p>
              <img src="images/reitium-homepage.png" alt="reitium homepage" />
            </a>

            {/* Thirdwave */}
            <a
              href="https://www.thirdwavelabs.com/"
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["defi"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["gaming"] ||
                  category["ethereum"] ||
                  category["polygon"] ||
                  category["bsc"] ||
                  category["flow"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Thirdwave")}
            >
              <img
                id="thirdwave-logo"
                src="logos/thirdwave-logo.svg"
                alt="thirdwave logo"
              />
              <p className="paragraph" style={{ color: "#000080" }}>
                <span style={{ fontWeight: "bold" }}>data analyst from october 2022 to february 2023</span>
              </p>
              <p className="paragraph" style={{ color: "#000066" }}>
                blockchain data insights for gaming dapps
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#00004D" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> blockchain data scraping scripts
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#000033" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> blockchain data insights for gaming dapps, ethereum
                blockchain, polygon blockchain, binance smart chain blockchain,
                flow blockchain, web3
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#00001A" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> python, sql, postgresql, amazon web services,
                ethereum, polygon, binance smart chain, flow
              </p>
              <img
                src="images/thirdwave-homepage.png"
                alt="thirdwave homepage"
              />
            </a>

            {/* Metaphy Labs */}
            <a
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Metaphy")}
            >
              <img
                id="metaphy-logo"
                src="logos/metaphy-logo.jpeg"
                alt="metaphy logo"
              />
              <p className="paragraph" style={{ color: "#4A5D23" }}>
                <span style={{ fontWeight: "bold" }}>backend and devops developer from october 2022 to november 2022</span>
              </p>
              <p className="paragraph" style={{ color: "#3E4D1C" }}>
                a web3 product studio designed to help founders
                plan and develop their ideas into profitable web3 products
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#323E16" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> backend and devops for finflux and hivetool clients
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#262E10" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> social platforms integration and analytics
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#1A1F0A" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> node.js, express, prisma, sql, postgresql, docker,
                amazon web services, aws s3, aws api gateway, aws lambda, aws
                cloudwatch, aws sqs, aws eventbridge
              </p>
              <img src="images/metaphy-homepage.png" alt="metaphy homepage" />
            </a>

            {/* Mobo */}
            <a
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["nfts"] ||
                  category["ethereum"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Mobo")}
            >
              <img src="logos/mobo-logo.png" alt="mobo logo" />
              <p className="paragraph" style={{ color: "#8A2BE2" }}>
                <span style={{ fontWeight: "bold" }}>ideation to mvp in 6 weeks from may 2022 to june 2022</span>
              </p>
              <p className="paragraph" style={{ color: "#9400D3" }}>
                a link-in-bio tool for nft creators
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#8B008B" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> full-stack mvp
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#8B008B" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> twitter and wallet authentication, dashboard,
                profiles, analytics, nfts, ethereum blockchain, web3
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#4B0082" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, javascript, react, redux, node.js,
                express, twitter api, nftport api, opensea api, rarible api,
                nosql, mongodb, moralis, amazon web services
              </p>
              <img src="images/mobo-homepage.png" alt="mobo homepage" />
            </a>

            {/* Mogle */}
            <a
              target="_blank"
              rel="noreferrer"
              className={
                category["all"] ||
                  category["apps"] ||
                  category["fintech"] ||
                  category["defi"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["ethereum"] ||
                  category["polygon"]
                  ? "card card-link"
                  : "hidden"
              }
              onClick={() => handleCardClick("Mogle")}
            >
              <img
                id="mogle-logo"
                src="logos/mogle-logo.png"
                alt="mogle logo"
              />
              <p className="paragraph" style={{ color: "#DAA520" }}>
                <span style={{ fontWeight: "bold" }}>founder from june 2021 to may 2022</span>
              </p>
              <p className="paragraph" style={{ color: "#B8860B" }}>product suite for freelancers in web3</p>
              <p className="paragraph" style={{ textAlign: "left", color: "#996515" }}>
                <span style={{ fontWeight: "bold" }}>deliverables:</span> 5 different iterations of full-stack mvps
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#7B4F1E" }}>
                <span style={{ fontWeight: "bold" }}>features:</span> wallet authentication, dashboard, analytics,
                marketplace, smart contracts, defi, nfts, daos, ethereum
                blockchain, polygon blockchain, web3
              </p>
              <p className="paragraph" style={{ textAlign: "left", color: "#5C3A27" }}>
                <span style={{ fontWeight: "bold" }}>tech stack:</span> html, css, javascript, react, redux, node.js,
                express, python, flask, numpy, scipy, nosql, dynamodb, moralis,
                amazon web services, ethereum, polygon
              </p>
              <img src="images/mogle-homepage.png" alt="mogle homepage" />
            </a>

            {/* Slant */}
            {/*
              <a
                href="http://www.slantapp.com/slant/homepage.php"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["apps"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("Slant")}
              >
                <img
                  id="slant-logo"
                  src="logos/slant-logo.jpg"
                  alt="slant logo"
                />
                <p className="paragraph">
                  media platform to gauge public opinion on topics ranging from
                  politics to film
                </p>
                <p className="paragraph">
                  tech stack: html, css, javascript, jquery, vue.js, php, sql,
                  mysql, amazon web services
                </p>
                <p className="paragraph">
                  features: email authentication, profiles, search, posts,
                  comments, likes, polls, notifications, inbox, settings
                </p>
                <img src="images/slant-homepage.png" alt="slant homepage" />
              </a>
            */}

            {/* freestyler */}
            {/*
              <a
                href="https://bit.ly/jclvsh-freestyler"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["ai"] ? "card card-link" : "hidden"
                }
                onClick={() => handleCardClick("freestyler")}
              >
                <h1 className="heading">freestyler</h1>
                <p className="paragraph">
                  generate a freestyle rap from your favorite rapper using ai
                </p>
                <p className="paragraph">
                  tech stack: html, css, javascript, react, next.js, chatgpt
                </p>
                <p className="paragraph">features: ai</p>
                <img
                  src="images/freestyler-homepage.png"
                  alt="freestyler homepage"
                />
              </a>
            */}

            {/* jcgen */}
            {/*
              <a
                href="https://bit.ly/jclvsh-jcgen"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["ai"] ? "card card-link" : "hidden"
                }
                onClick={() => handleCardClick("jcgen")}
              >
                <h1 className="heading">jcgen</h1>
                <p className="paragraph">generate images of me using ai</p>
                <p className="paragraph">
                  tech stack: html, css, javascript, react, next.js, jupyter
                  notebook, google colab, stable diffusion
                </p>
                <p className="paragraph">features: ai</p>
                <img src="images/jcgen-homepage.png" alt="jcgen homepage" />
              </a>
            */}

            {/* hip */}
            {/*
              <a
                href="https://bit.ly/jclvsh-hip"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["ethereum"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("hip")}
              >
                <h1 className="heading">hip</h1>
                <p className="paragraph">get hip to new artists and music</p>
                <p className="paragraph">
                  tech stack: react, hardhat, metamask, solidity, ethereum
                </p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, ethereum
                  blockchain, web3
                </p>
                <img src="images/hip-homepage.png" alt="hip homepage" />
              </a>
            */}

            {/* portal */}
            {/*
              <a
                href="https://bit.ly/jclvsh-portal"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["nfts"] || category["ethereum"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("portal")}
              >
                <h1 className="heading">portal</h1>
                <p className="paragraph">
                  exclusive access to your fav artists and influencers
                </p>
                <p className="paragraph">tech stack: react, hardhat, metamask, solidity, ethereum</p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, nfts, ethereum
                  blockchain, web3
                </p>
                <img src="images/portal-homepage.png" alt="portal homepage" />
              </a>
            */}

            {/* fanime */}
            {/*
              <a
                href="https://bit.ly/jclvsh-fanime"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] ||
                    category["gaming"] ||
                    category["nfts"] ||
                    category["ethereum"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("fanime")}
              >
                <h1 className="heading">fanime</h1>
                <p className="paragraph">battle of the animes</p>
                <p className="paragraph">
                  tech stack: react, hardhat, metamask, solidity, ethereum
                </p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, nfts, gaming,
                  ethereum blockchain, web3
                </p>
                <img src="images/fanime-homepage.png" alt="fanime homepage" />
              </a>
            */}

            {/* YeDAO */}
            {/*
              <a
                href="https://bit.ly/jclvsh-yedao"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] ||
                  category["nfts"] ||
                  category["daos"] ||
                  category["ethereum"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("YeDAO")}
              >
                <h1 className="heading">YeDAO</h1>
                <p className="paragraph">the DAO for Kanye West memes</p>
                <p className="paragraph">
                  tech stack: react, hardhat, metamask, solidity, ethereum
                </p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, nfts, governance
                  tokens, DAOs, ethereum blockchain, web3
                </p>
                <img src="images/yedao-homepage.png" alt="yedao homepage" />
              </a>
            */}

            {/* LNS */}
            {/*
              <a
                href="https://bit.ly/jclvsh-lns"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["nfts"] || category["polygon"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("LNS")}
              >
                <h1 className="heading">lavish name service</h1>
                <p className="paragraph">mint a lavish name service domain</p>
                <p className="paragraph">
                  tech stack: react, hardhat, metamask, solidity, polygon
                </p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, nfts, polygon
                  blockchain, web3
                </p>
                <img src="images/lns-homepage.png" alt="lns homepage" />
              </a>
            */}

            {/* gifly */}
            {/*
              <a
                href="https://bit.ly/jclvsh-gifly"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["solana"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("gifly")}
              >
                <h1 className="heading">gifly</h1>
                <p className="paragraph">dank gifs and memes</p>
                <p className="paragraph">
                  tech stack: react, anchor, phantom, rust, solana
                </p>
                <p className="paragraph">
                  features: wallet authentication, programs, solana blockchain,
                  web3
                </p>
                <img src="images/gifly-homepage.png" alt="gifly homepage" />
              </a>
            */}

            {/* xclsv */}
            {/*
              <a
                href="https://bit.ly/jclvsh-xclsv"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["nfts"] || category["solana"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("xclsv")}
              >
                <h1 className="heading">xclsv</h1>
                <p className="paragraph">
                  xclsv drops
                </p>
                <p className="paragraph">tech stack: react, anchor, phantom, rust, metaplex, solana</p>
                <p className="paragraph">
                  features: wallet authentication, programs, nfts, solana blockchain, web3
                </p>
                <img src="images/xclsv-homepage.png" alt="xclsv homepage" />
              </a>
            */}

            {/* twooter */}
            {/*
              <a
                href="https://bit.ly/jclvsh-twooter"
                target="_blank"
                rel="noreferrer"
                className={
                  category["all"] || category["gaming"] || category["terra"]
                    ? "card card-link"
                    : "hidden"
                }
                onClick={() => handleCardClick("twooter")}
              >
                <h1 className="heading">twooter</h1>
                <p className="paragraph">a first person twooter</p>
                <p className="paragraph">
                  tech stack: react, terrain, rust, terra
                </p>
                <p className="paragraph">
                  features: wallet authentication, smart contracts, gaming, terra
                  blockchain, web3
                </p>
                <img src="images/twooter-homepage.png" alt="twooter homepage" />
              </a>
            */}
          </div >
        </div >
      </div >
    </>
  );
};

export default Jclvsh;
