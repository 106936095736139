import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import amplitude from "amplitude-js";
import "./styles/index.css";
import "./styles/jclvsh.css";

const REACT_APP_AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;

amplitude.getInstance().init(REACT_APP_AMPLITUDE_API_KEY);

ReactDOM.render(<App />, document.querySelector("#root"));
