import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Home";

const Home = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  const handleLinkClick = (link) => {
    amplitude.getInstance().logEvent("Click " + PAGE + " " + link);
  };

  return (
    <>
      <div className="section">
        <div className="div-block">
          <h1 className="heading">josh choi</h1>
          <p className="paragraph">founder @ jclvsh</p>
          <p className="paragraph">
            formerly @ columbia university, nasa goddard space flight center,
            johns hopkins institute of cell engineering
          </p>
          <div className="div-block-2">
            <a
              href="mailto:joshchoi4881@gmail.com"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("GMail")}
                src="logos/gmail.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://calendly.com/joshchoi/30min"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Calendly")}
                src="logos/googlecalendar.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/joshchoi4881/"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("LinkedIn")}
                src="logos/linkedin.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://medium.com/@joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Medium")}
                src="logos/medium.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
            <a
              href="https://github.com/joshchoi4881"
              className="w-inline-block"
              target="_blank"
              rel="noreferrer"
            >
              <img
                onClick={() => handleLinkClick("Github")}
                src="logos/github.svg"
                loading="lazy"
                alt=""
                className="image"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
