import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header id="nav" className="sticky-nav">
        <nav className="w-container">
          <ul className="nav-grid w-list-unstyled">
            <li id="w-node-_7ca07a89-f95e-4234-2453-6ddbe9c0890f-7d0ecfa3">
              <Link to="/" className="nav-logo-link link">
                <img src="logos/jclvsh.png" alt="" className="nav-logo" />
              </Link>
            </li>
            {/*
              <li>
                <Link to="/mogle" className="nav-link header-link">
                  mogle
                </Link>
              </li>
            */}
            <li>
              <Link to="/jclvsh" className="nav-link header-link">
                jclvsh
              </Link>
            </li>
            <li>
              <Link to="/ynho" className="nav-link header-link">
                ynho
              </Link>
            </li>
            <li>
              <Link to="/social" className="nav-link header-link">
                social
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
