import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "NotFound";

const NotFound = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page");
  }, []);

  return (
    <>
      <div className="section">
        <h1 className="heading">page not found</h1>
      </div>
    </>
  );
};

export default NotFound;
